import RatingContainer from '../../../Componant/BonusUi/Rating/Rating'

const Rating = () => {
  return (
    <div className='page-body'>
      <RatingContainer />
    </div>
  )
}

export default Rating