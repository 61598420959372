import AlertContainer from '../../Componant/Ui-Kits/Alert/Alert'

const Alert = () => {
  return (
    <div className='page-body'>
      <AlertContainer />
    </div>
  )
}

export default Alert