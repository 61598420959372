import TimelineContainer from '../../../Componant/BonusUi/Timeline/Timeline'

const Timeline = () => {
  return (
    <div className='page-body'>
      <TimelineContainer />
    </div>
  )
}

export default Timeline