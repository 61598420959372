import { Href } from "../../utils/Constant"

export const totalStudentData = [
    {
        student:"42,954",
        title:"Total Students",
        color:"danger",
        icon:"down",
        percentage:"- 17.06%",
        detail:"than last 6 Month",
        image:"student.png",
        class:"student"
    },
    {
        student:"659",
        title:"Total Teachers",
        color:"success",
        icon:"up",
        percentage:"+27.02%",
        detail:"than last 4 Month",
        image:"teacher.png",
        class:"student-2"
    },
    {
        student:"984",
        title:"Events",
        color:"success",
        icon:"up",
        percentage:"+ 12.01%",
        detail:"than last 8 Month",
        image:"calendar.png",
        class:"student-3"
    },
    {
        student:"1,984",
        title:"Invoice Status",
        color:"danger",
        icon:"down",
        percentage:"- 15.02%",
        detail:"than last 5 Month",
        image:"invoice.png",
        class:"student-4"
    },
]

export const assignmentData = [
    {
       id:"0542",
       image:"1.png",
       link:Href,
       name:"Gary payi",
       subjects:"Accounts",
       startDate:"12 May 2024",
       endDate:"20 May 2024",
       value:"80",
       color:"primary"
    },
    {
        id:"9548",
        image:"2.png",
        link:Href,
        name:"Ralph Waters",
        subjects:"UI/UX Design",
        startDate:"06 May 2024",
        endDate:"16 May 2024",
        value:"60",
        color:"secondary"
     },
     {
        id:"2950",
        image:"3.png",
        link:Href,
        name:"Edwin Day",
        subjects:"Mathematics",
        startDate:"25 Sep 2024",
        endDate:"30 May 2024",
        value:"50",
        color:"warning"
     },
     {
        id:"9605",
        image:"4.png",
        link:Href,
        name:"Aaron Hogan",
        subjects:"Computer App",
        startDate:"23 May 2024",
        endDate:"26 May 2024",
        value:"65",
        color:"tertiary"
     },
     {
        id:"9548",
        image:"2.png",
        link:Href,
        name:"Ralph Waters",
        subjects:"UI/UX Design",
        startDate:"06 May 2024",
        endDate:"16 May 2024",
        value:"60",
        color:"secondary"
     },
]

export const liveMeetingData= [
    1,2,3,4,5,6,7,8,9,10
]

export const enrolledData = [
    {
        color:"primary",
        title:"After Effects CC Masterclass",
        time:"10:20 -11:30"
    },
    {
        color:"secondary",
        title:"Design from A to Z",
        time:"09:00 -10:30"
    },
    {
        color:"warning",
        title:"Graphic Design Bootcamp",
        time:"15:00 -16:00"
    },
    {
        color:"tertiary",
        title:"The Ultimate Guide to Usabillity",
        time:"13:25 -14:30"
    },
    {
        color:"success",
        title:"After Effects CC Masterclass",
        time:"12:45 -14:20"
    },
]

export const featuredCourseData = [
    {
        image:"1.png",
        title:"Mobile UX",
        link:Href,
        name:"Erin Mooney",
        date:"Feb 15",
        ratting:"4.8",
        type:"UX/UI Design",
        id:"colorChangeButton"
    },
    {
        image:"2.png",
        title:"Illustration",
        link:Href,
        name:"Elsie Lemon",
        date:"Mar 22",
        ratting:"2.3",
        type:"Web Designer",
        id:"colorChangeButton1"
    },
    {
        image:"3.png",
        title:"Design System",
        link:Href,
        name:"Anna Green",
        date:"Jun 28",
        ratting:"1.5",
        type:"Developer",
        id:"colorChangeButton2"
    },
    {
        image:"4.png",
        title:"Leadership",
        link:Href,
        name:"John Elliot",
        date:"Apr 04",
        ratting:"42.4",
        type:"UX/UI Design",
        id:"colorChangeButton3"
    },
    {
        image:"5.png",
        title:"Latest Figma",
        link:Href,
        name:"Dylan Field",
        date:"jun 01",
        ratting:"5.4",
        type:"Graphic Designer",
        id:"colorChangeButton4"
    }
]