import MdeEditorContainer from '../../../../Componant/Miscellaneous/Editor/MdeEditor/MdeEditor'

const MdeEditor = () => {
  return (
    <div className='page-body'>
      <MdeEditorContainer />
    </div>
  )
}

export default MdeEditor