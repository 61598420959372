import SweetAlertContainer from '../../../Componant/BonusUi/SweetAlert/SweetAlert'

const SweetAlert = () => {
  return (
    <div className='page-body'>
      <SweetAlertContainer />
    </div>
  )
}

export default SweetAlert