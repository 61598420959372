import UsersProfileContainer from '../../../../Componant/Application/Users/UsersProfile/UsersProfile'

const UsersProfile = () => {
  return (
    <div className='page-body'>
      <UsersProfileContainer />
    </div>
  )
}

export default UsersProfile