import PrivateChatContainer from '../../../../Componant/Application/Chat/PrivateChat/PrivateChat'

const PrivateChat = () => {
  return (
    <div className='page-body'>
      <PrivateChatContainer />
    </div>
  )
}

export default PrivateChat