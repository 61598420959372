import SwitchContainer from '../../../../Componant/Forms/FormsWidgets/Switch/Switch'

const Switch = () => {
  return (
    <div className='page-body'>
      <SwitchContainer />
    </div>
  )
}

export default Switch