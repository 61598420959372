import RibbonsContainer from '../../../Componant/BonusUi/Ribbons/Ribbons'

const Ribbons = () => {
  return (
    <div className='page-body'>
      <RibbonsContainer />
    </div>
  )
}

export default Ribbons