import FeatherIconsContainer from '../../Componant/Icons/FeatherIcons/FeatherIcons'

const FeatherIcons = () => {
  return (
    <div className='page-body'>
      <FeatherIconsContainer />
    </div>
  )
}

export default FeatherIcons