import LeafletMapsContainer from '../../../../Componant/Miscellaneous/Maps/LeafletMaps/LeafletMaps'

const LeafletMaps = () => {
  return (
    <div className='page-body'>
      <LeafletMapsContainer />
    </div>
  )
}

export default LeafletMaps