import AceEditorContainer from '../../../../Componant/Miscellaneous/Editor/AceEditor/AceEditor'

const AceEditor = () => {
  return (
    <div className='page-body'>
      <AceEditorContainer />
    </div>
  )
}

export default AceEditor