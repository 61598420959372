import LearningListContainer from '../../../../Componant/Miscellaneous/Learning/LearningList/LearningList'

const LearningList = () => {
  return (
    <div className='page-body'>
      <LearningListContainer />
    </div>
  )
}

export default LearningList