import SupportTicketContainer from '../../../Componant/Miscellaneous/SupportTicket/SupportTicket'

const SupportTicket = () => {
  return (
    <div className='page-body'>
      <SupportTicketContainer />
    </div>
  )
}

export default SupportTicket