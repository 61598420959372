import ChartJsContainer from '../../../Componant/Charts/ChartJs/ChartJs'

const ChartJs = () => {
  return (
    <div className='page-body'>
      <ChartJsContainer />
    </div>
  )
}

export default ChartJs