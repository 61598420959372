import ContainerEducation from '../../../Componant/Dashboard/Education/Education'

const EducationDashboard = () => {
  return (
    <div className="page-body">
      <ContainerEducation />
    </div>
  )
}

export default EducationDashboard