import FormsValidationContainer from '../../../../Componant/Forms/FormsControl/FormsValidation/FormsValidation'

const FormsValidation = () => {
  return (
    <div className='page-body'>
      <FormsValidationContainer />
    </div>
  )
}

export default FormsValidation