import TourContainer from '../../../Componant/BonusUi/Tour/Tour'

const Tour = () => {
  return (
    <div className='page-body'>
      <TourContainer />
    </div>
  )
}

export default Tour