import FormWizardOneContainer from '../../../../Componant/Forms/FormsLayout/FormWizardOne/FormWizardOne'

const FormWizardOne = () => {
  return (
    <div className='page-body'>
      <FormWizardOneContainer />
    </div>
  )
}

export default FormWizardOne