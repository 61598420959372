import BootstrapTabsContainer from '../../Componant/Ui-Kits/BootstrapTabs/BootstrapTabs'

const BootstrapTabs = () => {
  return (
    <div className='page-body'>
      <BootstrapTabsContainer />
    </div>
  )
}

export default BootstrapTabs