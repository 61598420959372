import DataSourceContainer from '../../../../Componant/Tables/DataTables/DataSource/DataSource'

const DataSource = () => {
  return (
    <div className='page-body'>
      <DataSourceContainer />
    </div>
  )
}

export default DataSource