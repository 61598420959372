import CreativeCardContainer from '../../../Componant/BonusUi/CreativeCard/CreativeCard'

const CreativeCard = () => {
  return (
    <div className='page-body'>
      <CreativeCardContainer />
    </div>
  )
}

export default CreativeCard