import { useState } from 'react';
import { Form, FormGroup, Input, Label } from 'reactstrap';
import { Link, useNavigate } from "react-router-dom";
import { Btn, H4, H6, P } from '../../../../AbstractElements';
import { EmailAddress, EmailsPlaceHolder, ForgotPassword, Password, RememberPassword, SignIn, SignInAccount, SignInWith, LinkedInHeading, TwitterHeading, FacebookHeading } from '../../../../utils/Constant';
import { Facebook, Linkedin, Twitter } from 'react-feather';
import CommonLogo from './CommonLogo';
import axios from 'axios';
import { toast } from 'react-toastify';

const LoginForm = ({ alignLogo }: { alignLogo: string }) => {
  const [showPassWord, setShowPassWord] = useState(false);
  const [email, setEmail] = useState("");  // Email state
  const [password, setPassword] = useState("");  // Password state
  const [rememberMe, setRememberMe] = useState(false); // Remember me state
  const [loading, setLoading] = useState(false);  // Loading state for UI feedback
  const navigate = useNavigate();

  // Handle login
  const handleLogin = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);

    try {
      // Make API call to login
      const response = await axios.post('http://sentiment.airabot.id:5000/auth/login', {
        email,
        password,
      });

      if (response.status === 200 && response.data.status === true) {
        // Get token and user_id directly from the response (no `.data` used)
        const token = response.data.token; // Token from the response
        const userId = response. data.user_id; // User ID from the response

        // Save token and user info in localStorage or sessionStorage based on "Remember Me"
        if (rememberMe) {
          localStorage.setItem("login", JSON.stringify(true)); // Save login status
          localStorage.setItem("token", token); // Save the token in localStorage
          localStorage.setItem("user_id", userId); // Save the user ID in localStorage
        } else {
          sessionStorage.setItem("login", JSON.stringify(true)); // Save login status in sessionStorage
          sessionStorage.setItem("token", token); // Save the token in sessionStorage
          sessionStorage.setItem("user_id", userId); // Save the user ID in sessionStorage
        }

        // Navigate to the dashboard or home page
        navigate(`${process.env.PUBLIC_URL}/dashboard/default`);
      } else {
        toast.error("Invalid email or password!");  // Show error toast if login fails
      }
    } catch (error) {
      // Handle error if the API call fails (e.g., network error)
      toast.error("An error occurred. Please try again!");  // Show error if API call fails
    } finally {
      setLoading(false);  // Stop loading indicator
    }
  };

  return (
    <div className="login-card login-dark">
      <div>
        <div>
          <CommonLogo alignLogo={alignLogo} />
        </div>
        <div className="login-main">
          <Form className="theme-form" onSubmit={handleLogin}>
            <H4>{SignInAccount}</H4>
            <P>{"Enter your email & password to login to the app"}</P>

            {/* Email input */}
            <FormGroup>
              <Label className="col-form-label">{EmailAddress}</Label>
              <Input
                type="email"
                required
                placeholder={EmailsPlaceHolder}
                value={email}
                onChange={(e) => setEmail(e.target.value)} // Handle email input change
              />
            </FormGroup>

            {/* Password input */}
            <FormGroup>
              <Label className="col-form-label">{Password}</Label>
              <div className="form-input position-relative">
                <Input
                  type={showPassWord ? "text" : "password"}
                  placeholder="*********"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)} // Handle password input change
                />
                <div className="show-hide" onClick={() => setShowPassWord(!showPassWord)}>
                  <span className={showPassWord ? "show" : ""} />
                </div>
              </div>
            </FormGroup>

            {/* Remember me and Forgot Password */}
            <FormGroup className="mb-0 position-relative">
              <div className="checkbox p-0">
                <Input
                  id="checkbox1"
                  type="checkbox"
                  checked={rememberMe}
                  onChange={() => setRememberMe(!rememberMe)} // Toggle rememberMe state
                />
                <Label className="text-muted" htmlFor="checkbox1">{RememberPassword}</Label>
              </div>
              <Link className="forgot-link" to={`${process.env.PUBLIC_URL}/authentication/forgetpassword`}>
                {ForgotPassword}
              </Link>

              {/* Login Button */}
              <div className="text-end mt-3">
                <Btn color="primary" block disabled={loading}>
                  {loading ? "Logging in..." : SignIn}
                </Btn>
              </div>
            </FormGroup>

            {/* Social media login */}
            <H6 className="text-muted mt-4 or">{SignInWith}</H6>
            <div className="social mt-4">
              <div className="btn-showcase">
                <Link className="btn btn-light" to="https://www.linkedin.com/login" target="_blank" rel="noreferrer">
                  <Linkedin className="txt-linkedin" />{LinkedInHeading}
                </Link>
                <Link className="btn btn-light" to="https://twitter.com/login?lang=en" target="_blank" rel="noreferrer">
                  <Twitter className="txt-twitter" />{TwitterHeading}
                </Link>
                <Link className="btn btn-light" to="https://www.facebook.com/" target="_blank" rel="noreferrer">
                  <Facebook className="txt-fb" />{FacebookHeading}
                </Link>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
