import HoverEffectContainer from '../../../../Componant/Miscellaneous/Gallery/HoverEffect/HoverEffect'

const HoverEffect = () => {
  return (
    <div className='page-body'>
      <HoverEffectContainer />
    </div>
  )
}

export default HoverEffect