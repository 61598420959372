import ThemifyIconsContainer from '../../Componant/Icons/ThemifyIcons/ThemifyIcons'

const ThemifyIcons = () => {
  return (
    <div className='page-body'>
      <ThemifyIconsContainer />
    </div>
  )
}

export default ThemifyIcons