import BlogSingleContainer from '../../../../Componant/Miscellaneous/Blog/BlogSingle/BlogSingle'

const BlogSingle = () => {
  return (
    <div className='page-body'>
      <BlogSingleContainer />
    </div>
  )
}

export default BlogSingle