import BasicTableContainer from '../../../../Componant/Tables/ReactstrapTable/BasicTable/BasicTable'

const BasicTable = () => {
  return (
    <div className='page-body'>
      <BasicTableContainer />
    </div>
  )
}

export default BasicTable