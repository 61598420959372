import FaqContainer from '../../../Componant/Miscellaneous/Faq/Faq'

const Faq = () => {
  return (
    <div className='page-body'>
      <FaqContainer />
    </div>
  )
}

export default Faq