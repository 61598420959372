import DatePickerContainer from '../../../../Componant/Forms/FormsWidgets/DatePicker/DatePicker'

const DatePicker = () => {
  return (
    <div className='page-body'>
      <DatePickerContainer />
    </div>
  )
}

export default DatePicker