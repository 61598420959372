import RaisedStyleContainer from '../../../Componant/Buttons/RaisedStyle/RaisedStyle'

const RaisedStyle = () => {
  return (
    <div className='page-body'>
      <RaisedStyleContainer />
    </div>
  )
}

export default RaisedStyle