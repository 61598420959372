import GridContainer from "../../Componant/Ui-Kits/Grid/Grid"

const Grid = () => {
  return (
    <div className='page-body'>
        <GridContainer />
    </div>
  )
}

export default Grid