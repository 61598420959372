export const defaultPaginationsData = [
    {
      text: "If the pagination component is used to navigate between a set of search results, an appropriate label could be ",
      code: 'aria-label="Search results pages"',
    },
  ];
  
  export const activeDisablePaginationsData = [
    {
      text: "Use ",
      code: ".disabled ",
    },
    {
      text: "for links that appear un-clickable and",
      code: " .active",
    },
    {
      text: " to indicate the current page.",
    },
  ];
  
  export const iconPaginationData = [
    {
      text: "Use an icon or symbol in place of text for some pagination links.",
    },
  ];
  
  export const roundedPaginationData = [
    {
      text: "Used in rounded pagination.Use an icon or symbol in place of text for some pagination links.",
      code: "[.rounded-circle]",
    },
  ];
  
  
  export const alignmentPaginationData = [
    {
      text: "Change the alignment of pagination components with flexbox utilities. For example, with ",
      code: ".justify-content-center:.",
    },
  ];
  
  export const alignmentDataList = [
    {
      className: "justify-content-center m-b-30",
      active: true,
      color: "danger",
      smallText:true
    },
    {
      className: "justify-content-end",
      color: "success",
    },
  ];
  
  export const sizingPaginationData = [
    {
      text: "Use ",
      code: "[pagination-lg/pagination-md/pagination-lg]",
    },
    {
      text: " for additional sizes.",
    },
  ];
  
  export const sizingPaginationDataList = [
    {
      className: "m-b-30",
      size: "md",
    },
    {
      size: "sm",
    },
  ];