export const sliesOnlyData = [
    {
      text: "The ",
      code: ".active",
    },
    {
      text: " class needs to be added to one of the slides otherwise the carousel will not be visible.",
      code: ".d-block",
    },
    {
      text: " and ",
      code: ".w-100 ",
    },
    {
      text: " on carousel images to prevent browser default image alignment.",
    },
  ];
  
  export const sliesOnlyDataList = [
    {
      id: 1,
      image: "slider/11.jpg",
    },
    {
      id: 2,
      image: "slider/9.jpg",
    },
    {
      id: 3,
      image: "slider/6.jpg",
    },
  ];
  
  export const withControlData = [
    {
      text: "Use the ",
      code: ".carousel-control-prev",
    },
    {
      text: " & ",
      code: "carousel-control-next",
    },
    {
      text: " through previous and next controls. We recommend using ",
      code: "<button>",
    },
    {
      text: " elements, but you can also use ",
      code: "<a>",
    },
    {
      text: " elements with ",
      code: "role='button'.",
    },
  ];
  
  export const withControlDataList = [
    {
      id: 4,
      image: "slider/6.jpg",
    },
    {
      id: 5,
      image: "slider/7.jpg",
    },
    {
      id: 6,
      image: "slider/10.jpg",
    },
    {
      id: 7,
      image: "slider/4.jpg",
    },
    {
      id: 8,
      image: "slider/1.jpg",
    },
  ];
  
  export const autoPlayData = [
    {
      text: "Use the ",
      code: ".carousel-control-prev",
    },
    {
      text: " & ",
      code: "carousel-control-next",
    },
    {
      text: " through previous and next controls. We recommend using ",
      code: "<button>",
    },
    {
      text: " elements, but you can also use ",
      code: "<a>",
    },
    {
      text: " elements with ",
      code: "role='button'.",
    },
  ];
  
  export const autoPlayDataList = [
    {
      id: 9,
      image: "slider/1.jpg",
    },
    {
      id: 10,
      image: "slider/2.jpg",
    },
    {
      id: 11,
      image: "slider/3.jpg",
    },
    {
      id: 12,
      image: "slider/4.jpg",
    },
    {
      id: 13,
      image: "slider/5.jpg",
    },
    {
      id: 14,
      image: "slider/6.jpg",
    },
    {
      id: 15,
      image: "slider/7.jpg",
    },
    {
      id: 16,
      image: "slider/8.jpg",
    },
    {
      id: 17,
      image: "slider/9.jpg",
    },
    {
      id: 18,
      image: "slider/10.jpg",
    },
    {
      id: 19,
      image: "slider/11.jpg",
    },
  ];
  
  export const withIndicatorData = [
    {
      text: "Use the ",
      code: ".carousel-indicators",
    },
    {
      text: " through carousel indicates.",
    },
  ];
  
  export const withIndicatorDataList = [
    {
      id: 20,
      image: "slider/3.jpg",
    },
    {
      id: 21,
      image: "slider/8.jpg",
    },
    {
      id: 22,
      image: "slider/10.jpg",
    },
  ];
  
  export const withCaptionData = [
    {
      text: "Add captions to your slides easily with the",
      code: " .carousel-caption",
    },
    {
      text: " element within any ",
      code: ".carousel-item",
    },
  ];
  
  export const withCaptionDataList = [
    {
      id: 23,
      image: "slider/9.jpg",
      captionText: "You can watch folks you wouldn't have in your house amuse you in your living room thanks to the development of television.",
      captionHeader: "The area in the house that is most comfortable.",
    },
    {
      id: 24,
      image: "slider/1.jpg",
      captionText: "Regardless of how big or tiny your home is, think about hiring an interior designer. They give you a calm living atmosphere in addition to decorating your home.",
      captionHeader: "Drawing Room",
    },
    {
      id: 25,
      image: "slider/2.jpg",
      captionText: "If you want to alter your way of life, start with redesigning your house's interior.",
      captionHeader: "House Interior",
    },
  ];
  
  export const crossFadeData = [
    {
      text: "Add ",
      code: "carousel-fade",
    },
    {
      text: " to your carousel to animate slides with a fade transition instead of a slide. Depending on your carousel content, you may want to add",
      code: " .bg-body",
    },
    {
      text: " or some custom CSS to the .carousel-items for proper cross-fading.",
    },
  ];
  
  export const crossFadeDataList = [
    {
      id: 26,
      image: "slider/1.jpg",
    },
    {
      id: 27,
      image: "slider/11.jpg",
    },
    {
      id: 28,
      image: "slider/7.jpg",
    },
    {
      id: 29,
      image: "slider/10.jpg",
    },
  ];
  
  export const itemIntervalData = [
    {
      text: "Add ",
      code: 'data-bs-interval=""',
    },
    {
      text: " to a",
      code: " .carousel-item",
    },
    {
      text: "  to change the amount of time to delay between automatically cycling to the next item.",
    },
  ];
  
  export const itemIntervalDataList = [
    { id: 1, image: '5.jpg', interval: '10000' },
    { id: 2, image: '2.jpg', interval: '2000' },
    { id: 3, image: '10.jpg' },
  ];
  
  export const disableTouchData = [
    {
      text: "Carousels support swiping left/right on touchscreen devices to move between slides. This can be disabled using the ",
      code: "data-bs-touch ",
    },
    {
      text: " attribute.",
    },
  ];
  
  export const disableTouchDataList = [
    {
      id: 30,
      image: "slider/4.jpg",
    },
    {
      id: 31,
      image: "slider/8.jpg",
    },
    {
      id: 32,
      image: "slider/6.jpg",
    },
  ];
  
  export const darkVariantData = [
    {
      text: "Add ",
      code: ".carousel-dark",
    },
    {
      text: "  to the ",
      code: ".carousel",
    },
    {
      text: "  for darker controls, indicators, and captions. ",
    },
  ];
  
  export const darkVariantDataList = [
    {
      id: 33,
      image: "slider/1.jpg",
      captionText: "If you have been dreaming about bringing your living room together, our designers are here to help. Come see what we can do for your space.",
      captionHeader: "We decorate our homes",
    },
    {
      id: 34,
      image: "slider/3.jpg",
      captionText: "A sofa is the ideal spot to enjoy a movie, nod off, and wake up.",
      captionHeader: "This couch is a great topic of conversation.",
    },
    {
      id: 35,
      image: "slider/6.jpg",
      captionText: "A house you can create with your friends is a great place.",
      captionHeader: "Sometimes all you really need to unwind is a comfortable couch.",
    },
  ];