import AvatarsContainer from '../../Componant/Ui-Kits/Avatars/Avatars'

const Avatars = () => {
  return (
    <div className='page-body'>
      <AvatarsContainer />
    </div>
  )
}

export default Avatars