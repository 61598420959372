import CkEditorContainer from '../../../../Componant/Miscellaneous/Editor/CkEditor/CkEditor'

const CkEditor = () => {
  return (
    <div className='page-body'>
      <CkEditorContainer />
    </div>
  )
}

export default CkEditor