import { Navigate, Outlet } from "react-router-dom";

const PrivateRoute: React.FC = () => {
  // Mengambil item login dari localStorage dan memastikan itemnya valid
  const login = JSON.parse(localStorage.getItem("login") || "false");

  // Jika login ditemukan dan valid, render children
  // Jika login tidak ditemukan atau false, arahkan ke halaman login
  return login ? <Outlet /> : <Navigate to={`${process.env.PUBLIC_URL}/login`} />;
};

export default PrivateRoute;
