import PaginationsContainer from '../../../Componant/BonusUi/Paginations/Paginations'

const Paginations = () => {
  return (
    <div className='page-body'>
      <PaginationsContainer />
    </div>
  )
}

export default Paginations