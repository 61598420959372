import MasonryGalleryContainer from '../../../../Componant/Miscellaneous/Gallery/MasonryGallery/MasonryGallery'

const MasonryGallery = () => {
  return (
    <div className='page-body'>
      <MasonryGalleryContainer />
    </div>
  )
}

export default MasonryGallery