import ProductsContainer from '../../../../Componant/Application/Ecommerce/Products/Products'

const Products = () => {
  return (
    <div className='page-body'>
      <ProductsContainer />
    </div>
  )
}

export default Products