import TooltipContainer from '../../Componant/Ui-Kits/Tooltip/Tooltip'

const Tooltip = () => {
  return (
    <div className='page-body'>
      <TooltipContainer />
    </div>
  )
}

export default Tooltip