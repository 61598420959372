import ContactsContainer from '../../../Componant/Application/Contacts/Contacts'

const Contacts = () => {
  return (
    <div className='page-body'>
      <ContactsContainer />
    </div>
  )
}

export default Contacts