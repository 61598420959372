export const userCardData = [
    {
      id: 1,
      avatar: "other-images/profile.png",
      icon: "profile-check",
      name: "Brooklyn Simmons",
      email: "@brookly.simmons",
      totalPost: "1,908",
      follower: "34.0k",
      following: "897",
    },
    {
      id: 2,
      avatar: "avtar/3.jpg",
      icon: "profile-check",
      name: "Mark Jecno",
      email: "@mark.jeco",
      totalPost: "875",
      follower: "12.0k",
      following: "1400",
    },
    {
      id: 3,
      avatar: "avtar/11.jpg",
      icon: "profile-check",
      name: "Dev John",
      email: "@john.dev",
      totalPost: "1,274",
      follower: "15.0k",
      following: "1874",
    },
    {
      id: 4,
      avatar: "avtar/16.jpg",
      icon: "profile-check",
      name: "Johan Deo",
      email: "@deo.johan",
      totalPost: "500",
      follower: "8.0k",
      following: "570",
    },
    {
      id: 5,
      avatar: "user/7.jpg",
      icon: "profile-check",
      name: "Douglas Reichel",
      email: "@reichel.douglas",
      totalPost: "460",
      follower: "2k",
      following: "350",
    },
    {
      id: 6,
      avatar: "avtar/4.jpg",
      icon: "profile-check",
      name: "Lisa lillian",
      email: "@lisa.lillian",
      totalPost: "547",
      follower: "3.5k",
      following: "822",
    },
    {
      id: 7,
      avatar: "user/1.jpg",
      icon: "profile-check",
      name: "Olivia rose",
      email: "@rose.olivia",
      totalPost: "868",
      follower: "1k",
      following: "742",
    },
    {
      id: 8,
      avatar: "user/2.png",
      icon: "profile-check",
      name: "Sarah Karen",
      email: "@karen.sarah",
      totalPost: "972",
      follower: "2.5k",
      following: "864",
    },
  ];