import ApexChartContainer from '../../../Componant/Charts/ApexChart/ApexChart'

const ApexChart = () => {
  return (
    <div className='page-body'>
      <ApexChartContainer />
    </div>
  )
}

export default ApexChart