import BlogDetailsContainer from '../../../../Componant/Miscellaneous/Blog/BlogDetails/BlogDetails'

const BlogDetails = () => {
  return (
    <div className='page-body'>
      <BlogDetailsContainer />
    </div>
  )
}

export default BlogDetails