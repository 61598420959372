import AccordionContainer from '../../Componant/Ui-Kits/Accordion/Accordion'

const Accordion = () => {
  return (
    <div className='page-body'>
      <AccordionContainer />
    </div>
  )
}

export default Accordion