import TreeViewContainer from '../../../Componant/BonusUi/TreeView/TreeView'

const TreeView = () => {
  return (
    <div className="page-body">
      <TreeViewContainer />
    </div>
  )
}

export default TreeView