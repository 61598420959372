import ContainerGeneral from '../../../Componant/Widgets/General/ContainerGeneral'

const General = () => {
  return (
    <div className='page-body'>
      <ContainerGeneral />
    </div>
  )
}

export default General