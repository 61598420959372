export const customScroll = [
    {
      text: "Used ",
      code: ".vertical-scroll ",
    },
    {
      text: "through design scrollbar.",
    },
  ];
  
  export const smallSizeScroll = [
    {
      text: "Use the ",
      code: ".scrollbar-margins ",
    },
    {
      text: "through small scroll and image is draggable. ",
    },
  ];
  
  export const badgeScroll = [
    {
      text: "Use the ",
      code: ".badge ",
    },
    {
      text: " class through create more badges and ",
      code: ".vertical-scroll ",
    },
    {
      text: "used as vertical scroll.",
    },
  ];
  
  export const badgeScrollList = [
    {
      title: "Lola Stanford",
      color: "danger",
      text: "Issue",
    },
    {
      title: "Caitlin Coungeau",
      color: "primary",
      text: "Social",
    },
    {
      title: "Graciela W. McClaran",
      color: "danger",
      text: "Issue",
    },
    {
      title: "Derek T. Aldridge",
      color: "warning",
      text: "Freelance",
    },
    {
      title: "Annie A. Riley",
      color: "primary",
      text: "Social",
    },
    {
      title: "Hana J. Boyd",
      color: "danger",
      text: "Issue",
    },
    {
      title: "Karen R. Pryce",
      color: "warning",
      text: "Freelance",
    },
    {
      title: "Cordie C. Pope",
      color: "primary",
      text: "Social",
    },
  ];
  
  export const profileScroll = [
    {
      text: "Use the ",
      code: ".list-group-item ",
    },
    {
      text: "through made profile and with used ",
      code: ".vertical-scroll. ",
    },
  ];
  
  export const profileScrollList = [
    {
      text: "Sharon C. Obrien",
      src: "user/2.jpg",
    },
    {
      text: "Bryan A. Owens",
      src: "user/5.jpg",
    },
    {
      text: "Ronald M. Enger",
      src: "user/12.png",
    },
    {
      text: "Herbert A. Clary",
      src: "user/14.png",
    },
    {
      text: "Dino A. Cannon",
      src: "user/6.jpg",
    },
    {
      text: "Danny A. McLean",
      src: "user/3.jpg",
    },
    {
      text: "Betty K. Curtis",
      src: "user/2.jpg",
    },
  ];
  
  //Scrollable Contents
  export const scrollableContentData = [
    {
      text: "Use the ",
      code: ".list-group-item ",
    },
    {
      text: "through made profile and with used ",
      code: ".vertical-scroll. ",
    },
  ];
  
  export const scrollableContentDataList = [
    {
      src: "user/10.jpg",
      text: "Gabrielle Fahey",
      mail: "GabrielleFahey@dayrep.com",
      days: "10 days ago",
    },
    {
      src: "user/2.jpg",
      text: "Lucinda Moseley",
      mail: "LucindaMoseley@teleworm.us",
      days: "3 days ago",
    },
    {
      src: "user/12.png",
      text: "Francis K. Henriques",
      mail: "FrancisKHenriques@teleworm.us",
      days: "2 days ago",
    },
    {
      src: "user/14.png",
      text: "Jose A. Seay",
      mail: "JoseASeay@rhyta.com",
      days: "15 days ago",
    },
    {
      src: "user/3.jpg",
      text: "Phil F. Cunningham",
      mail: "PhilFCunningham@dayrep.com",
      days: "6 days ago",
    },
    {
      src: "user/7.jpg",
      text: "Richard E. Johnson",
      mail: "RichardEJohnson@teleworm.us",
      days: "120 days ago",
    },
    {
      src: "user/2.png",
      text: "Lawrence L. Nash",
      mail: "LawrenceLNash@jourrapide.com",
      days: "8 days ago",
    },
  ];
  
  export const horizontalScrollData = [
    {
      text: "Use the ",
      code: ".horizontal-scroll ",
    },
    {
      text: "through move content horizontally.",
    },
  ];
  
  export const horizontalScrollDataList = ["scrollbar/fashion2.jpg", "scrollbar/fashion3.jpg", "scrollbar/fashion4.jpg", "scrollbar/fashion5.jpg", "scrollbar/fashion6.jpg"];
  
  export const bothSideScrollData = [
    {
      text: "Use the ",
      code: "visible-scroll ",
    },
    {
      text: "through visible both side scrollbar.",
    },
  ];