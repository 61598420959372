export const customSwitchData = [
    {
      text: "A switch has the markup of a custom checkbox but uses the .form-switch class to render a ",
      code: "toggle switch.",
    },
  ];
  
  export const customSwitchDataList = [
    {
      id: 1,
      sm: "6",
      item: ["primary", "secondary", "success", "danger", "warning", "info", "dark"],
    },
    {
      id: 2,
      sm: "6",
      cardClass: "radius-none",
      formClass: "default-square",
      item: ["primary", "secondary", "success", "danger", "warning", "info", "dark"],
    },
    {
      id: 3,
      cardClass: "common-flex",
      disabled: true,
      item: ["primary", "secondary", "success", "danger", "warning", "info", "dark"],
    },
  ];
  
  export const iconSwitchData = [
    {
      text: "Use the ",
      code: ".switch-state ",
    },
    {
      text: "and ",
      code: "icon-state ",
    },
    {
      text: "through defined icons switches.",
    },
  ];
  
  export const iconSwitchDataList = [
    {
      color: "primary",
      text: "Primary Switch",
    },
    {
      color: "secondary",
      text: "Secondary Switch",
    },
    {
      color: "success",
      text: "Success Switch",
    },
    {
      color: "danger",
      text: "Danger Switch",
    },
    {
      color: "warning",
      text: "Warning Switch",
    },
    {
      color: "info",
      text: "Info Switch",
    },
    {
      color: "dark",
      text: "Dark Switch",
    },
  ];
  
  export const uncheckedSwitchData = [
    {
      text: "Use the ",
      code: ".bg-*",
    },
    {
      text: " class through created default switches. ",
    },
  ];
  
  export const uncheckedSwitchDataList = [
    {
      color: "primary",
      text: "Unchecked Primary Switch",
    },
    {
      color: "secondary",
      text: "Unchecked Secondary Switch",
    },
    {
      color: "success",
      text: "Unchecked Success Switch",
    },
    {
      color: "danger",
      text: "Unchecked Danger Switch",
    },
    {
      color: "warning",
      text: "Unchecked Warning Switch",
    },
    {
      color: "info",
      text: "Unchecked Info Switch",
    },
    {
      color: "dark",
      text: "Unchecked Dark Switch",
    },
  ];
  
  export const borderIconSwitchData = [
    {
      text: "Use the ",
      code: ".switch-state ",
    },
    {
      text: "and ",
      code: "icon-state ",
    },
    {
      text: " class through created outline and icons. ",
    },
  ];
  
  export const borderIconSwitchDataList = [
    {
      color: "primary",
      text: "Bordered Primary Switch",
    },
    {
      color: "secondary",
      text: "Bordered Secondary Switch",
    },
    {
      color: "success",
      text: "Bordered Success Switch",
    },
    {
      color: "danger",
      text: "Bordered Danger Switch",
    },
    {
      color: "warning",
      text: "Bordered Warning Switch",
    },
    {
      color: "info",
      text: "Bordered Info Switch",
    },
    {
      color: "dark",
      text: "Bordered Dark Switch",
    },
  ];
  
  export const disableOutlineSwitchData = [
    {
      text: "Use the ",
      code: ".switch-outline ",
    },
    {
      text: " class through created outline. ",
    },
  ];
  
  export const disableOutlineSwitchDataList = ["primary", "secondary", "success", "danger", "warning", "info", "dark"];
  
  export const variationSwitchData = [
    {
      text: "Use the ",
      code: ".tgl-skewed/tgl-flip",
    },
    {
      text: " class through created variations. ",
    },
  ];
  
  export const variationSwitchDataList = [
    {
      id: "cb3",
      inputClass: "tgl-skewed",
      text: "Skewed",
      offData:"OFF",
      onData:"ON"
    },
    {
      id: "cb5",
      inputClass: "tgl-flip",
      text: "Flip",
      offData:"Nope",
      onData:"Yeah!"
    },
  ];
  
  export const variationSwitchList = [
    {
      inputClass: "tgl-skewed",
      text: "Checked",
      flexClass: "icon-state",
      color: "warning",
    },
    {
      inputClass: "tgl-flip",
      text: "Flat",
      color: "success",
    },
  ];
  
  export const switchSizingData = [
    {
      text: "Use the ",
      code: ".switch-sm ",
    },
    {
      text: "and ",
      code: ".switch-lg ",
    },
    {
      text: "class through defined switch sizing.",
    },
  ];
  
  export const switchSizingDataList = [
    {
      label: "Small Size",
      flexClass: "switch-sm",
      defaultChecked: true,
    },
    {
      label: "Small Size Unhecked",
      flexClass: "switch-sm",
    },
    {
      label: "Normal Size",
      defaultChecked: true,
    },
    {
      label: "Normal Unhecked",
    },
    {
      label: "Disabled State",
      disabled: true,
    },
    {
      label: "Large Size",
      flexClass: "switch-lg",
      defaultChecked: true,
    },
    {
      label: "Large Unhecked",
      flexClass: "switch-lg",
    },
    {
      label: "Disabled",
      flexClass: "switch-lg",
      disabled: true,
    },
  ];
  
  export const switchIconData = [
    {
      text: "Use the ",
      code: ".icon-state",
    },
    {
      text: " class through visible icons in switches",
    },
  ];
  
  export const switchIconDataList = [
    {
      label: "Small Size",
      flexClass: "switch-sm",
      defaultChecked: true,
    },
    {
      label: "Small Size Unhecked",
      flexClass: "switch-sm",
    },
    {
      label: "Normal Size",
      defaultChecked: true,
    },
    {
      label: "Normal Unhecked",
    },
    {
      label: "Disabled State",
      disabled: true,
    },
    {
      label: "Large Size",
      flexClass: "switch-lg",
      defaultChecked: true,
    },
    {
      label: "Large Unhecked",
      flexClass: "switch-lg",
    },
    {
      label: "Disabled",
      flexClass: "switch-lg",
      disabled: true,
    },
  ];
  