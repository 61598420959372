import OrderHistoryContainer from '../../../../Componant/Application/Ecommerce/OrderHistory/OrderHistory'

const OrderHistory = () => {
  return (
    <div className='page-body'>
      <OrderHistoryContainer />
    </div>
  )
}

export default OrderHistory