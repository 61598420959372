import FlagIconsContainer from "../../Componant/Icons/FlagIcons/FlagIconsContainer"

const FlagIcons = () => {
  return (
    <div className='page-body'>
      <FlagIconsContainer />
    </div>
  )
}

export default FlagIcons