// src/env.ts

export const ENV = {
  API_URL: process.env.REACT_APP_API_URL,
  PUBLIC_URL: process.env.PUBLIC_URL, // Adding default value
} as const;

// Validate environment variables
const validateEnv = (): void => {
  if (!ENV.API_URL) {
    throw new Error('REACT_APP_API_URL is not defined in environment variables');
  }
  if (!ENV.PUBLIC_URL) {
    console.warn('PUBLIC_URL is not defined, using default value: http://localhost:3000');
  }
};

validateEnv();

export default ENV;