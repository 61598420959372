import UserCardsContainer from '../../../../Componant/Application/Users/UserCards/UserCards'

const UserCards = () => {
  return (
    <div className='page-body'>
      <UserCardsContainer />
    </div>
  )
}

export default UserCards