import MasonryImageDescContainer from '../../../../Componant/Miscellaneous/Gallery/MasonaryImageDesc/MasonaryImageDesc'

const MasonryImageDesc = () => {
  return (
    <div className='page-body'>
      <MasonryImageDescContainer />
    </div>
  )
}

export default MasonryImageDesc