import WhetherIconContainer from '../../Componant/Icons/WhetherIcon/WhetherIcon'

const WhetherIcon = () => {
  return (
    <div className='page-body'>
      <WhetherIconContainer />
    </div>
  )
}

export default WhetherIcon