import ProjectListContainer from '../../../../Componant/Application/Project/ProjectList/ProjectList'

const ProjectList = () => {
  return (
    <div className='page-body'>
      <ProjectListContainer />
    </div>
  )
}

export default ProjectList