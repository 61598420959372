export const tourInfoData = [
    {
      iconClass: "envelope",
      text: "   Email",
      spanText: "William@jourrapide.com",
    },
    {
      iconClass: "calendar",
      text: "   BOD",
      className: "ttl-sm-mb-0",
      spanText: "02 January 1988",
    },
  ];
  
  export const tourAddressData = [
    {
      colClass: "mt-0 mt-sm-3",
      iconClass: "phone",
      text: "   Contact Us",
      className: "ttl-xs-mt",
  
      spanText: "US 310-273-0666",
    },
    {
      iconClass: "location-arrow",
      text: "   Location",
      className: "ttl-sm-mb-0",
      spanText: "4377 Libby Street Beverly Hills",
    },
  ];
  
  export const socialData = [
    {
      href: "https://www.facebook.com/",
      icon: "facebook",
    },
    {
      href: "https://accounts.google.com/",
      icon: "google-plus",
    },
    {
      href: "https://twitter.com/",
      icon: "twitter",
    },
    {
      href: "https://www.instagram.com/",
      icon: "instagram",
    },
    {
      href: "https://rss.app/",
      icon: "rss",
    },
  ];
  
  export const commonFooterData = [
    {
      className: "b-r-gray pe-3",
      icon: "heart",
      text: " Like",
    },
    {
      className: "b-r-gray pe-3",
      icon: "comment",
      text: " Comment",
    },
    {
      icon: "paper-plane",
      text: " Share",
    },
  ];
  
  //Tour
  export const tourSteps = [
    {
      selector: ".step1",
      content: "This is Mofi profile",
    },
    {
      selector: ".step2",
      content: "Change Mofi profile image here",
    },
    {
      selector: ".step3",
      content: "This is your profile details",
    },
    {
      selector: ".step4",
      content: "This is your social details",
    },
    {
      selector: ".step5",
      content: "This is your first Post",
    },
    {
      selector: ".step6",
      content: "This is your social details",
    },
    {
      selector: ".step7",
      content: "This is your social details",
    },
    {
      selector: ".step8",
      content: "This is your social details",
    },
    {
      selector: ".step9",
      content: "This is your social details",
    },
  ];