import TypeaheadContainer from '../../../../Componant/Forms/FormsWidgets/Typeahead/Typeahead'

const Typeahead = () => {
  return (
    <div className='page-body'>
      <TypeaheadContainer />
    </div>
  )
}

export default Typeahead