import LetterBoxContainer from '../../../Componant/Application/LetterBox/LetterBox'

const LetterBox = () => {
  return (
    <div className='page-body'>
      <LetterBoxContainer />
    </div>
  )
}

export default LetterBox