import ICOIconContainer from '../../Componant/Icons/ICOIcon/ICOIcon'

const ICOIcon = () => {
  return (
    <div className='page-body'>
      <ICOIconContainer />
    </div>
  )
}

export default ICOIcon