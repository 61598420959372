import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import LayoutRoutes from "./LayoutRoutes";
import { authRoutes } from "./AuthRoutes";
import Login from "../Componant/Authentication/Login";

const RouterData = () => {
  const login = localStorage.getItem("login");
  return (
    <BrowserRouter basename={"/"}>
      <Routes>
      {/* Jika sudah login, redirect ke halaman default */}
      {login ? (
          <Route
            path={`${process.env.PUBLIC_URL}/`}
            element={<Navigate to={`${process.env.PUBLIC_URL}/dashboard/default`} />}
          />

          
        ) : (
          <Route path={`${process.env.PUBLIC_URL}/`} element={<Login />} />
        )}
        <Route path={"/"} element={<PrivateRoute />}> 
        </Route>

        <Route path={`/*`} element={<LayoutRoutes />} />

        {authRoutes.map(({ path, Component }, i) => (
          <Route path={path} element={Component} key={i} />
        ))}
        <Route path={`${process.env.PUBLIC_URL}/login`} element={<Login />} />
      </Routes>
    </BrowserRouter>
  );
};

export default RouterData;
