export const basicContainerStyle = {
    height: "500px",
  };
  
  export const basicCenter = {
    lat: -3.745,
    lng: -38.523,
  };
  
  export const markerContainerStyle = {
    height: "500px",
  };
  
  export const markerCenter = {
    lat: -3.745,
    lng: -38.523,
  };
  
  export const polygonContainerStyle = {
    height: "500px",
  };
  
  export const polygonCenter = {
    lat: -3.745,
    lng: -38.523,
  };
  
  export const polylineContainerStyle = {
    height: "500px",
  };
  
  export const polylineCenter = {
    lat: -3.745,
    lng: -38.523,
  };
  
  export const worldMapData = [
    {
      text: "Below Map is displaying the world map.",
    },
  ];
  
  export const usaMapData = [
    {
      text: "Below Map is displaying the USA map.",
    },
  ];
  
  export const usaPosition = { lat: 50, lng: 10 };
  
  export const indianMapData = [
    {
      text: "Below Map is displaying the India map.",
    },
  ];
  
  export const indianMapPosition = { lat: 20.5937, lng: 78.9629 };
  
  export const australiaMapData = [
    {
      text: "Below Map is displaying the Australia map.",
    },
  ];
  
  export const australiaMapPosition = { lat: -25.2744, lng: 130.7751 };