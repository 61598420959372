import BreadcrumbContainer from '../../../Componant/BonusUi/Breadcrumb/Breadcrumb'

const Breadcrumb = () => {
  return (
    <div className='page-body'>
      <BreadcrumbContainer />
    </div>
  )
}

export default Breadcrumb