import BasicCardContainer from '../../../Componant/BonusUi/BasicCard/BasicCard'

const BasicCard = () => {
  return (
    <div className='page-body'>
      <BasicCardContainer />
    </div>
  )
}

export default BasicCard