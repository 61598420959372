import TagAndPillsContainer from '../../Componant/Ui-Kits/TagAndPills/TagAndPills'

const TagAndPills = () => {
  return (
    <div className='page-body'>
      <TagAndPillsContainer />
    </div>
  )
}

export default TagAndPills