import TwoFactorContainer from '../../../../Componant/Forms/FormsLayout/TwoFactor/TwoFactor'

const TwoFactor = () => {
  return (
    <div className='page-body'>
      <TwoFactorContainer />
    </div>
  )
}

export default TwoFactor