import AdvanceInitContainer from '../../../../Componant/Tables/DataTables/AdvanceInit/AdvanceInit'

const AdvanceInit = () => {
  return (
    <div className='page-body'>
      <AdvanceInitContainer />
    </div>
  )
}

export default AdvanceInit