export const linkColorData = [
    {
      text: "Use the ",
      code: ".alert-link",
    },
    {
      text: " utility class to quickly provide matching colored links within any alert",
    },
  ];

  export const darkThemeList = [
    {
      title: "Secondary Alert ",
      color: "secondary",
    },
    {
      title: " Success Alert ",
      color: "success",
    },
    {
      title: " Info Alert ",
      color: "info",
    },
  ];

  export const darkThemeLists = [
    {
      title: " Danger Alert ",
      color: "danger",
    },
    {
      title: " Light Alert ",
      color: "light",
      className: "dark",
    },
    {
      title: " Dark Alert ",
      color: "dark",
    },
  ];

  export const lightThemeList = ["secondary", "success", "info"];

export const lightThemeLists = [
  {
    title: "Danger Light Alert",
    color: "light-danger",
    className: "txt-danger",
  },
  {
    title: "White Light Alert",
    color: "light-light",
    className: "txt-dark",
  },
  {
    title: "Dark Light Alert",
    color: "light-dark",
    className: "txt-dark",
  },
];

export const outLineLightData = [
  {
    text: "Use the ",
    code: ".border-*",
  },
  {
    text: "utility class to quickly provide matching border and border-width within any alert.",
  },
];

export const iconTextData = [
  {
    text: "Use the ",
    code: ".dismiss-text",
  },
  {
    text: " class to add dismiss text instead of icon",
  },
];

export const dismissingDarkData = [
  {
    text: "Use the ",
    code: ".alert-dismissible",
  },
  {
    text: " utility class to quickly remove the alerts.",
  },
];

export const dismissingLightData = [
  {
    text: "Use the ",
    code: ".alert-dismissible",
  },
  {
    text: " utility class to quickly remove the alerts.",
  },
];

export const liveAlertData = [
  {
    text: "Click the button below to show an alert,then dismiss it with the built-in close button.",
  },
];

export const leftData = [
  {
    text: "Use the ",
    code: ".border-left-wrapper",
  },
  {
    text: "to change border-left radius.",
  },
];

export const additionalData = [
  {
    text: "Use the ",
    code: ".alert",
  },
  {
    text: " utility class to quickly provide additional content within any alerts.",
  },
];

export const additionalList = [
    {
      color: "secondary",
      title: "Something went wrong! Please, chrome update.",
      paragraph1: "The duty of notification is imposed upon the head of the family, and also upon the medical practitioner who may be in attendance on the patient.",
      paragraph2: "The emergency notification system is free and is available in all 50 states.",
    },
    {
      color: "success",
      title: "Please! Hidden cameras were not installed.",
      paragraph1: "Due to increasingly accessible technology, hidden cameras have grown in popularity among regular people in recent years.",
      paragraph2: "Consider moving clocks and plush animals from your area if you think they may be concealing cameras because they are both portable items.",
    },
  ];