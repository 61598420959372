import ListsContainer from '../../Componant/Ui-Kits/Lists/Lists'

const Lists = () => {
  return (
    <div className='page-body'>
      <ListsContainer />
    </div>
  )
}

export default Lists