export const breadcrumbData = [
    {
      text: "You can set breadcrumb using ",
      code: ".breadcrumb ",
    },
    {
      text: " class.",
    },
  ];
  
  export const dividerBreadcrumbData = [
    {
      text: "You can set breadcrumb using ",
      code: ".breadcrumb-icon",
    },
    {
      text: " class.",
    },
  ];
  
  export const withIconBreadcrumbData = [
    {
      text: "You can set breadcrumb using ",
      code: ".breadcrumb ",
    },
    {
      text: " class.",
    },
  ];
  
  export const variationOfBreadcrumbData = [
    {
      text: "You can set breadcrumb using ",
      code: ".breadcrumb ",
    },
    {
      text: "class through any icons sets.",
    },
  ];
  
  export const coloredBreadcrumbData = [
    {
      text: "You can set breadcrumb using ",
      code: ".breadcrumb ",
    },
    {
      text: " class and set background colors like ",
      code: "[.bg-*].",
    },
  ];
  
  export const coloredBreadcrumbDataList = [
    {
      className: "m-b-30 bg-warning",
      span: [
        {
          title: "Home",
  
        },
        {
          title: "Icons",
  
        },
        {
          activeTitle: "Flag-icon",
        },
      ],
    },
    {
      className: "m-0 bg-danger",
      span: [
        {
          title: "Home",
  
        },
        {
          title: "Gallery",
  
        },
        {
          activeTitle: "Gallery-grid",
        },
      ],
    },
  ];