import { Container, Row } from 'reactstrap'
import TwoFactorAuthentication from './TwoFactorAuthentication/TwoFactorAuthentication'
import EmailVerification from './EmailVerification/EmailVerification'

const TwoFactorContainer = () => {
  return (
    <Container fluid>
      <Row>
        <TwoFactorAuthentication />
        <EmailVerification />
      </Row>
    </Container>
  )
}

export default TwoFactorContainer