import PopoverContainer from '../../Componant/Ui-Kits/Popover/Popover'

const Popover = () => {
  return (
    <div className='page-body'>
      <PopoverContainer />
    </div>
  )
}

export default Popover