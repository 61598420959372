import DropdownContainer from '../../Componant/Ui-Kits/Dropdown/Dropdown'

const Dropdown = () => {
  return (
    <div className='page-body'>
      <DropdownContainer />
    </div>
  )
}

export default Dropdown