export const creditSelect= ["Select Type","Master","Visa"]

export const debitMonthSelect= ["Select Month","Jan","Fab","March","April"]

export const debitYearSelect= ["Select Year","2015","2016","2017","2024","2019"]


export const emiBankSelect = ["Bank Name", "SBI", "ICICI", "KOTAK", "BOB"];

 export const emiCardSelect= ["Select Card", "2", "3", "4", "5"];

 export const emiYearSelect = ["Select Duration", "2015-2016", "2016-2017", "2017-2024", "2024-2019"];
