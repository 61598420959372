import TableComponentContainer from '../../../../Componant/Tables/ReactstrapTable/TableComponent/TableComponent'

const TableComponent = () => {
  return (
    <div className='page-body'>
      <TableComponentContainer />
    </div>
  )
}

export default TableComponent