import TodoContainer from '../../../Componant/Application/Todo/Todo'

const Todo = () => {
  return (
    <div className='page-body'>
      <TodoContainer />
    </div>
  )
}

export default Todo