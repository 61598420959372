import ToastContainer from '../../../Componant/BonusUi/Toast/Toast'

const Toast = () => {
  return (
    <div className='page-body'>
      <ToastContainer />
    </div>
  )
}

export default Toast