import BookmarkContainer from '../../../Componant/Application/Bookmark/Bookmark'

const Bookmark = () => {
  return (
    <div className='page-body'>
      <BookmarkContainer />
    </div>
  )
}

export default Bookmark